import DfStore from "@/vue/domain/store/df-store";
import Utils from "@/vue/infrastructure/utils/helpers";
import { Component, Inject, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfContent from "@/vue/domain/content/df-content";
import DfUser from "@/vue/domain/user/df-user";
import moment from "moment";
import DfPaging from "@/vue/domain/paging/df-paging";

const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfStoreCard: DfStoreCardComponent,
    DfLoadingMask: () => import(/* webpackChunkName: 'df-loading-mask' */ "@/vue/components/df-loading-mask/df-loading-mask.vue"),
  },
  filters: {
    price: (value: string) => {
      const result: string = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR",
      }).format(parseFloat(value) || 0);
      return `${result}`;
    },
    dateFormat(value: Date | string): string {
      return value != null ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_PATTER) : "";
    },
    hoursFormat(value: Date | string): string {
      return value != null ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_HOURS_MINUTES) : "";
    },
  },
})
export default class DfPageAccountExpensesComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private expenses: Array<any> = [];
  private paging: DfPaging = null;
  private working = false;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get currentUser(): DfUser {
    return this.$store.getters.user;
  }

  beforeMount() {
    this.loadUserContents();
  }

  private loadUserContents(append = false) {
    if (this.currentUser) {
      this.paging && append ? this.paging.number++ : null;
      this.working = true;
      this.service
        .getUserContents({ typeCode: ["PAY"], paging: this.paging })
        .then((data: any) => {
          this.paging = data.paging;
          const expenses: Array<any> = data.userContents
            .filter((content: DfContent) => content.type.code === "PAY")
            .map((content: DfContent) => {
              const date: string = Utils.getPropertyValue(content, Utils.PROPERTY_CONTENT_DATE, "DATE");
              const total: string = Utils.getPropertyValue(content, Utils.PROPERTY_CONTENT_AMOUNT, "NUMBER");
              return {
                total: total,
                date: date,
              };
            });
          this.expenses = append ? [...this.expenses, ...expenses] : expenses;
          this.working = false;
        })
        .catch((error: Error) => {
          this.working = false;
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }
}
